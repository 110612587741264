@import "../../global.scss";

.navbar {
  width: 100vw;
  height: 70px;
  color: black;
  position: fixed;
  top: 0;
  z-index: 3;
  transition: all 1s ease;

  .wrapper {
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      text-decoration: none;
      color: black;
      font-size: large;
      font-weight: bold;
      transition: all 1s ease;
    }

    .left {
      display: flex;
      align-items: center;

      .logo {
        font-size: 40px;
        font-weight: 700;
        text-decoration: none;
        color: inherit;
        margin-right: 40px;
        color: white;
      }

      .itemContainer {
        display: flex;
        align-items: center;
        margin-left: 30px;
        text-align: center;
        color: white;

        .icon {
          font-size: 38px;
          margin-right: 8px;
        }

        span {
          font-size: 17px;
          font-weight: 400;
          font-weight: bold;
          display: inline;
          margin: 10px 10px 10px 0;
          padding: 2px 5px;
        }
      }
    }

    .right {
      .hamburger {
        width: 32px;
        height: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        span {
          width: 100%;
          height: 3px;
          transform-origin: left;
          background-color: black;
          transition: all 1s ease;
        }
      }
    }
  }

  &.active {
    color: white;

    a {
      color: white;
    }

    .hamburger {
      span {
        &:first-child {
          background-color: white;
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:last-child {
          background-color: white;
          transform: rotate(-45deg);
        }
      }
    }
  }
  @include mobile {
    display: none;
    .wrapper {
      padding: 1.5rem;
      a {
      }

      .left {
        .logo {
        }

        .itemContainer {
          display: none;
          .icon {
          }

          span {
          }
        }
      }

      .right {
        .hamburger {
          span {
          }
        }
      }
    }

    &.active {
      a {
      }

      .hamburger {
        span {
          &:first-child {
          }
          &:nth-child(2) {
          }
          &:last-child {
          }
        }
      }
    }
  }
}
