@import "../../global.scss";

#contact {
  background-color: white;
  display: flex;
  width: 100vw;
  .responsive-container {
    .row {
      .left {
        flex: 1 1;
        overflow: hidden;
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: stretch;
        justify-content: center;

        img {
          height: 100%;
          margin-right: -500px;
          max-width: 200%;
        }
      }
      .right {
        flex: 1;
        display: flex;
        z-index: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .formContainer {
          background-color: white;
          padding: 4rem;
          box-shadow: 6px 10px rgba(0, 0, 0, 0.267);

          form {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            gap: 2rem;

            .formTitle {
              font-size: 30px;
            }

            .submitButton {
              width: 6em;
              height: 60px;
              color: whitesmoke;
              background-color: $mainColor;
              border: none;
              border-radius: 10px;
              font-weight: 300;
              font-size: 18px;
              margin-right: 35rem;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 1rem;
              justify-content: space-around;

              .arrowIcon {
                display: inline;
                font-weight: 900;
              }

              &:hover {
                font-weight: 600;
              }

              &:focus {
                outline: none;
              }
            }
            span {
              color: $mainColor;
            }
          }
        }
      }
    }
  }
  @include mobile {
    background-color: white;
    display: flex;
    width: 100vw;
    padding: 30% 0;
    .responsive-container {
      .row {
        flex-direction: column;
        .left {
          flex:none;
          overflow: hidden;
          width: 100%;
          justify-content: space-between;
          img {
            max-width: 125%;
            margin-right:0;
            display:none;
          }
        }
        .right {
          width: 100%;
          .formContainer {
            padding: 10% 0 10%;
            width: 95%;
            box-shadow: none;
            padding-left:2rem;
            box-shadow: 6px 10px rgba(0, 0, 0, 0.267);
            form {
              .formTitle {
                margin-top:1rem;
              }

              .submitButton {
                margin-right:0;
                .arrowIcon {
                }

                &:hover {
                }

                &:focus {
                }
              }
              span {
              }
            }
          }
        }
      }
    }
  }
}
