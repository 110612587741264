@import "../../global.scss";

.portfolio {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0px 10% 0px;

  h1 {
    font-size: 6rem;
    z-index: 1;
  }

  ul {
    margin: 10px;
    list-style: none;
    display: flex;
    padding-top: 2rem;
    padding-bottom: 4rem;

    li {
      font-size: 3rem;
      padding: 1rem;
      margin-right: 4rem;
      border-radius: 5px;
      cursor: pointer;
      box-shadow: inset 0 0 0 0 $mainColor;
      -webkit-transition: all ease 0.8s;
      -moz-transition: all ease 0.8s;
      transition: all ease 0.8s;

      &.active {
        background-color: $mainColor;
        box-shadow: inset 300px 0 0 0 white;
      }
    }
  }
  .container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5%;

    .item {
      width: 30vw;
      height: 30vh;
      border-radius: 20px;
      margin: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgb(79, 126, 226);
      position: relative;
      box-shadow: 10px 10px rgba(0, 0, 0, 0.267);

      h3 {
        position: absolute;
        font-size: 20px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
        border-radius: 10px;
      }

      &:hover {
        color: white;
        background-color: rgb(79, 126, 226);
        border-radius: 10px;

        img {
          opacity: 0.3;
          z-index: 0;
        }
      }
    }
  }
  @include mobile {
    h1 {
      font-size: 50px;
    }
    ul {
      flex-wrap: wrap;
      justify-content: center;
      padding-bottom: 1rem;
      li {
        font-size: 30px;
        padding: 17px;
        border-radius: 5px;
        cursor: pointer;
        margin-bottom: 1rem;
        margin-right: 1rem;
        box-shadow: inset 0 0 0 0 #4f7ee2;
        transition: all ease 0.8s;
      }
    }
    .container {
      width: 100%;
      justify-content: space-evenly;
      .item {
        width: 21rem;
        height: 13rem;
        img {
          border-radius: 10%;
        }
      }
    }
  }
  .animate {
    animation-duration: 0.3s;
    animation-name: animate-fade;
    animation-delay: 0s;
    animation-fill-mode: backwards;
  }

  @keyframes animate-fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .animate.pop {
    animation-name: animate-pop;
    animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  }

  @keyframes animate-pop {
    0% {
      opacity: 0;
      transform: scale(0.5, 0.5);
    }

    100% {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
}
