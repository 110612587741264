@import "../../global.scss";

.menu {
  width: 430px;
  height: 100vh;
  display: inline-block;
  background-color: $mainColor;
  position: fixed;
  top: 0;
  right: -430px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 1s ease;

  &.active {
    right: 0;
  }

  ul {
    display: inline-block;
    text-align: center;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 40px;
    color: white;
    width: 100%;

    li {
      font-size: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0rem;
      height: 8rem;
      width: 100%;
      padding-left: 2rem;
      margin-bottom: 10rem;
      box-shadow: inset 0 0 0 0 #ffffff;
      -webkit-transition: all ease 0.8s;
      -moz-transition: all ease 0.8s;
      transition: all ease 0.8s;

      a {
        font-size: inherit;
        color: inherit;
        text-decoration: none;
      }

      &:hover {
        box-shadow: inset 800px 0 0 0 #ffffff;
        color: black;
      }
    }
  }
}
