.app {
  height: 100vh;
  min-height: 100%;
  .body,
  .html,
  .sections {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    overflow-x: hidden;
    scrollbar-width: none;

    .responsive-container {
      width: 100%;
      .row {
        display: flex;
        justify-content: space-between;
        gap: 2%;
        .col {
          width: 48%;
        }
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }

    > * {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      scroll-snap-align: start;
      scroll-behavior: smooth !important;
    }
  }
}
