@import "../../global.scss";

.works {
  background-color: $mainColor;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10% 0px 10% 0px;

  .responsive-container {
    text-align: -webkit-center;
    .row {
      width: 80%;
      .swiper {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 12px;
        box-shadow: 20px 20px rgba(0, 0, 0, 0.267);

        .swiper-slide {
          background-color: white;
          text-align: center;
          height: 1000px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 12px;

          .row {
            gap: 0;
            .left {
              text-align: left;
              .imagewrapper {
                img {
                  width: 10%;
                  padding-top: 3rem;
                }
              }
              h1 {
                font-size: 9rem;
                color: $mainColor;
              }
              .ml-title {
                font: size 7rem;
              }
              .mldesc {
                font-size: 2.5rem;
                line-height: normal;
              }
              .blogdesc {
              }
              .description {
                font-size: 3rem;
                line-height: normal;
              }
            }
            .right {
              align-self: center;
              img {
                transform: rotate(30deg);
                width: 120%;
              }
              .blogimg {
                width: 90%;
                transform: rotate(0deg);
              }
            }
          }
        }
      }
    }
  }

  @include mobile {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
    .responsive-container {
      text-align: -webkit-center;
      .row {
        width: 80%;
        flex-direction: column;
        .swiper {
          width: 100%;
          height: 100%;
          margin-left: auto;
          margin-right: auto;
          border-radius: 12px;
          box-shadow: 20px 20px rgba(0, 0, 0, 0.267);

          .swiper-slide {
            background-color: white;
            text-align: center;
            height: 90vh;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 12px;

            .row {
              gap: 0;
              .left {
                text-align: left;
                width: 100%;
                .imagewrapper {
                  text-align-last: center;
                  img {
                    margin-top: 0rem;
                    width: 50%;
                    padding-top: 1rem;
                  }
                }
                h1 {
                  font-size: 5rem;
                  color: $mainColor;
                }
                .blog-title {
                  font-size: 4rem;
                }
                .ml-title {
                  font-size: 4rem;
                }
                .mldesc {
                  font-size: 18px;
                }
                .blogdesc {
                }
                .description {
                  font-size: 18px;
                  line-height: normal;
                  text-align: center;
                }
              }
              .right {
                align-self: center;
                width: 100%;
                .machinelearning {
                  width: 60%;
                }
                img {
                  transform: rotate(0deg);
                  width: 100%;
                  padding-top: 0rem;
                }
                .blogimg {
                  width: 70%;
                  transform: rotate(0deg);
                }
              }
            }
          }
        }
      }
    }
  }
}
