@import "../../global.scss";

.inputfield {
  display: block;

  .label {
    display: inherit;
    font-size: large;
    font-family: sans-serif;
    color:rgb(129, 129, 156);
    font-size:large;
  }

  .input {
    outline: none;
    width: 100%;
    border: 2px;
    background: rgba(129, 129, 156, 0.171);
    margin-bottom: 2px;
    border-bottom: 2px solid grey;
    height: 35px;
    padding-left: 2px;
    padding-right: 2px;
  }
  @include mobile{
    width: 90%;
  }
}
