@import "../../global.scss";

.portfolioList {
    font-size: 25px;
    padding: 6px;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1;
    line-height: normal;

    &.active{
        background-size: 100px;
    }
}