@import "../../global.scss";

#intro {
  background: white;
  display: flex;
  width: 100%;
  padding: 100px 0px 5% 0px;
  .responsive-container {
    user-select: none;
    .row {
      display: flex;
      .left {
        overflow: hidden;
        z-index: 1;
        .imageContainer {
          height: calc(100vh - 70px);
          background-color: currentColor;
          border-radius: 60%;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          float: right;

          img {
            height: 115%;
            width: 115%;
            transform: scaleX(-1);
            border: hidden;
          }
        }
      }

      .right {
        display: flex;

        .typer {
          color: rgb(79, 126, 226);
          display: flex;
        }

        .wrapper {
          align-content: center;
          padding-left: 4rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          z-index: 1;

          h1 {
            font-size: 100px;
            margin: 10px 0;
            color: white;
          }

          h2 {
            font-size: 70px;
          }

          h3 {
            font-size: 60px;
          }
          .typer {
            display: contents;
            color: white;
          }
        }

        a {
          position: absolute;
          bottom: 0%;
          left: 60%;
          z-index: 1;
          img {
            width: 80px;
            animation: arrowBounce 2s infinite;
          }
        }
        @keyframes arrowBounce {
          0%,
          20%,
          50%,
          80%,
          100% {
            transform: translateY(0);
          }
          40% {
            transform: translateY(-30px);
          }
          60% {
            transform: translateY(-15px);
          }
        }
      }
    }
  }

  @include mobile {
    flex-direction: column;
    align-items: center;
    padding: 0px 0px 60% 0px;
    width: 100%;
    .responsive-container {
      .row {
        flex-direction: column;
        align-items: center;
        .left {
          display: flex;
          width: 100%;
          justify-content: space-around;
          .imageContainer {
            background-color: transparent;
            align-items: flex-start;
            height: fit-content;
            width: 100%;
            overflow: hidden;
            border-radius: 10%;
            img {
              height: 50%;
              width: 85vw;
              background-color: currentColor;
              border-radius: 100px;
            }
          }
        }
        .right {
          display: flex;
          justify-content: center;
          text-align: center;
          .typer {
            padding-right: 2rem;
          }
          .wrapper {
            align-items: center;
            height: 50%;
            padding: 0;
            h1 {
              font-size: 65px;
              margin: 10px 0;
              color: white;
            }

            h2 {
              font-size: 70px;
            }

            h3 {
              font-size: 50px;
            }
          }

          a {
            display: none;
          }
        }
      }
    }
  }
}
